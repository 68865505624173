import * as React from 'react';
import { useState } from 'react';
import { TextField, Container, Box, Button } from '@mui/material';
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { db } from '../../../settings/firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, collection, getDocs, addDoc, arrayUnion } from "firebase/firestore";

export default function Manage() {

    const [newID, setNewID] = useState('');
    const [oldID, setOldID] = useState('');
    const [profileField, setProfileField] = useState('');
    const [profileValue, setProfileValue] = useState('');
    const [field, setField] = useState('');
    const [newValue, setNewValue] = useState('');
    const [uid, setUid] = useState('');

    const handleOnReset = () => {
        setNewID('')
        setOldID('')
        setProfileField('')
        setProfileValue('')
    }

    let webinars;
    let name_;
    const TransferNewUser = async () => {
        try {
            const oldRef = doc(db, 'Profile', oldID);
            const newRef = doc(db, 'Profile', newID);
            const oldDocSnap = await getDoc(oldRef);
            const newDocSnap = await getDoc(newRef);

            if (oldDocSnap.exists()) {
                await setDoc(newRef, oldDocSnap.data())
                await updateDoc(newRef, {
                    displayName: newDocSnap.data().displayName,
                    email: newDocSnap.data().email,
                    photoURL: newDocSnap.data().photoURL,
                    loginType: newDocSnap.data().loginType
                })
                webinars = oldDocSnap.data().webinars
                name_ = newDocSnap.data().displayName
                console.log(webinars)
                console.log('Success!')
                return { webinars, name_ };
            } else {
                console.log("No such document!");
            }
        } catch (e) {
            console.log(e)
        }

    }

    const handleOnTransfer = async () => {
        await TransferNewUser();
    }


    const handleOnProfile = async () => {
        try {
            const profileRef = collection(db, 'Profile');
            const profileSnap = await getDocs(profileRef);
            profileSnap.forEach(async (item) => {
                let pRef = doc(db, 'Profile', item.id);
                await updateDoc((pRef), {
                    [profileField]: profileValue
                })
            })
        } catch (e) {
            console.log(e)
        }
    }


    const handleClick = async () => {
        const webinarsRef = collection(db, "Webinar");
        const querySnapshot = await getDocs(webinarsRef);
        console.log(field, newValue);
        let docs = []
        querySnapshot.forEach((doc_) => {

            docs.push(doc_.id);

        })
        console.log(docs);
        docs.forEach(async (id) => {
            try {
                const washingtonRef = doc(db, "Webinar", id);

                await updateDoc(washingtonRef, {
                    //需要請改此行
                    ...{ [field]: parseInt(newValue, 10) }




                });
            } catch (e) {
                console.log(e)
            }
        })


    }
    //webinar doc exmple
    const webinarExample = {
        "content": "test",
        "time": {
            "seconds": 1625551200,
            "nanoseconds": 0
        },
        "view": 14,
        "status": "已取消",
        "intro": "test\n\n《創業時，我們在知乎聊什麼？》是知乎的第一本出版作品，收錄了知乎從2011年架站到2014年書籍出版，前三年的創業問答精華內容，回答問題的人是知乎最早期的那批種子用戶，都是行業頂尖的大佬，包含了雷軍、李開復、張小龍、王興等人。\n\n本場次是一場好書分享，由主講人領讀並彙整書中的知識點，繪製成簡報向其他人分享。",
        "download": 0,
        "tags": [
            "test",
            "test"
        ],
        "host": [
            {
                "displayName": "test",
                "uid": uid
            }
        ],
        "share": 0,
        "file": "https://firebasestorage.googleapis.com/v0/b/deeplink-4a41c.appspot.com/o/docs%2Fppt%2F01.pptx?alt=media&token=45a2436a-fe8a-41a4-bc2c-5a1512a63d8f",
        "category": "test",
        "notify": 10,
        "title": "test",
        "doc_type": "ppt",
        "collection": 6,
        "cover": "",
        "claps": 10
    }
    // Add a new document with a generated id.
    const Add = async () => {
        try {
            const docRef = await addDoc(collection(db, "Webinar"), webinarExample);
            console.log("Document written with ID: ", docRef.id);
            const washingtonRef = doc(db, "Profile", uid);

            updateDoc(washingtonRef, { "webinars": arrayUnion(docRef.id) });
        } catch (e) {
            console.log(e)
        }

    }


    // const AddAll = async () => {
    //     ProfileArray.forEach(async (item) => {
    //         try {
    //             const docRef = await addDoc(collection(db, "Profile"), item);
    //             console.log("Document written with ID: ", docRef.id);
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     })
    // }
    // AddAll();


    //add img to webinar_covers
    // const handleCover = async (e) => {
    //     const storage = getStorage();
    //     async function waitEach(array, callback) {
    //         for (let i = 0; i < array.length; i++) {
    //             await callback(i, array[i]); //等它!
    //         }
    //     }

    //     try {
    //         if (e.target.files && e.target.files.length > 0) {
    //             console.log(e.target.files);
    //             waitEach(Object.values(e.target.files), async (index, file) => {
    //                 console.log(file.name.split('.')[0])
    //                 try {
    //                     const storageRef = ref(storage, '/webinar_covers/' + file.name.split('.')[0])
    //                     await uploadBytes(storageRef, file)
    //                     console.log('Uploading')
    //                 } catch (e) {
    //                     console.log(e)
    //                 }
    //             }
    //             );



    //         }

    //     }
    //     catch (e) {
    //         console.log(e)
    //     }
    // }

    return (
        <Container>
            帳號轉換
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                    my: 1,
                    display: 'flex'
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="new" label="新PID" variant="outlined" size="small" value={newID} onChange={(e) => setNewID(e.target.value)} />
                <TextField id="old" label="舊PID" variant="outlined" size="small" value={oldID} onChange={(e) => setOldID(e.target.value)} />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" onClick={handleOnTransfer}>移轉</Button> &nbsp;&nbsp;
                    <Button variant="outlined" onClick={handleOnReset}>清除</Button>
                </Box>
            </Box>

            在每個profile的document新增欄位
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                    my: 1,
                    display: 'flex'
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="new" label="欄位名稱" variant="outlined" size="small" value={profileField} onChange={(e) => setProfileField(e.target.value)} />
                <TextField id="old" label="欄位值" variant="outlined" size="small" value={profileValue} onChange={(e) => setProfileValue(e.target.value)} />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" onClick={handleOnProfile}>確定</Button> &nbsp;&nbsp;
                    <Button variant="outlined" onClick={handleOnReset}>清除</Button>
                </Box>
            </Box>

            在每個webinar的document新增欄位
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                    my: 1,
                    display: 'flex'
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="new" label="欄位名稱" variant="outlined" size="small" value={field} onChange={(e) => setField(e.target.value)} />
                <TextField id="old" label="欄位值" variant="outlined" size="small" value={newValue} onChange={(e) => setNewValue(e.target.value)} />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" onClick={handleClick}>確認</Button> &nbsp;&nbsp;
                </Box>
            </Box>

            新增webinar，需要客製化請自行修改
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                    my: 1,
                    display: 'flex'
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="cus" label="host id" variant="outlined" size="small" value={uid} onChange={(e) => setUid(e.target.value)} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" onClick={Add}>確認</Button> &nbsp;&nbsp;
                </Box>
            </Box>
            {/* <input accept=".jpg, .jpeg, .png" id="contained-button-file" multiple type="file" onChange={handleCover} /> */}
        </Container>
    );

}