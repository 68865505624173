import * as React from 'react';
import { useCallback, useState, useEffect } from 'react'
import { Box, Grid, Stack, Typography, styled, Button, TextField, Backdrop, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';

//icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CardContent from '@mui/material/CardContent';
import { AiFillSignal } from 'react-icons/ai';
import { BiSelection } from 'react-icons/bi';
import Divider from '@mui/material/Divider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhotoIcon from '@mui/icons-material/Photo';
import MuiAlert from '@mui/material/Alert';

//components
import Label from "../../webinarContent/label";
import webinarLoading from "../../../assets/img/common/webinarLoading.png";
import PersonCard from '../../webinarContent/PersonCard'
import FileCard from '../../webinarContent/fileCard/Card';

import moment from "moment"
import useWindowDimensions from '../../../customHook/useWindowDimension';
import { Link } from 'react-router-dom'
import Avatar from "../../webinarContent/Avatar";

//firebase
import { db } from '../../../settings/firebaseConfig';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Input = styled('input')({
    display: 'none',
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyButton = styled(Button)({
    width: 100,
    boxShadow: '0px 2px 2px 0px #3F79B3',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: '#3F79B3',
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    borderRadius: 100,
    '&:hover': {
        backgroundColor: 'rgba(63, 121, 179, 0.1)',
        borderColor: '#FFFFFF',
        boxShadow: '0px 2px 2px 0px #3F79B3',
        color: '#3F79B3'
    }
});

const useStyles = makeStyles((theme) => ({
    div: {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap"
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'confirmRow',
        },
    },
}));


const Title = styled(Typography)(() => ({
    color: "#212B36", fontWeight: 900, marginBottom: 12,

}));

const Content = styled(Typography)(() => ({
    color: "#212B36", marginBottom: 20, marginLeft: 2, whiteSpace: 'pre-line'

}));



export default function ConfirmPage({ confirmWebinarOpen, handleConfirmWebinarClose, confirmRow }) {
    const { width } = useWindowDimensions()
    const classes = useStyles()

    const [imagePreview, setImagePreview] = useState();
    const [imgCover, setImgCover] = useState('')
    const [meetURL, setMeetURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState({ open: false, status: "error", message: "" });


    const PersonAvatar = useCallback(() => <PersonCard host={confirmRow.hostId} direction={width >= 1000 ? "row" : "column"} cardDirection="column" zoom="100%" />, [confirmRow.hostId, width])

    const storage = getStorage();

    const handleImgClick = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageToLoad = new Image();
            imageToLoad.src = URL.createObjectURL(e.target.files[0]);
            imageToLoad.onload = async () => {
                if (imageToLoad.width !== 1920 || imageToLoad.height !== 1080) {
                    setOpenAlert({ open: true, status: "error", message: "圖片大小須為 1920 X 1080。" })
                }
                else {
                    try {
                        setImagePreview(file)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }

        }
    }

    const handleOnSubmit = async () => {
        try {
            if (!meetURL)
                setOpenAlert({ open: true, status: "error", message: "連結不可為空" })
            else if (!imagePreview && !imgCover)
                setOpenAlert({ open: true, status: "error", message: "請上傳一張封面照" })
            else {
                setIsLoading(true)

                if (imagePreview) {
                    const storageRef = ref(storage, '/webinar_covers/' + confirmRow.webinarID)
                    await uploadBytes(storageRef, imagePreview)
                }

                const webinarRef = doc(db, "Webinar", confirmRow.webinarID)
                await updateDoc(webinarRef, {
                    status: '已確認',
                    meetURL: meetURL,
                    replyTime: new Date()
                });
                for (const host of confirmRow.hostId) {
                    const docRef = doc(db, "Profile", host);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        if (docSnap.data().email !== "") {
                            var formData = new FormData()
                            formData.append("auth", "123")
                            formData.append("user_name", docSnap.data().displayName)
                            formData.append("recipient", docSnap.data().email)
                            formData.append("webinar_name", confirmRow.title)
                            formData.append("link", meetURL)
                            const requestOptions = {
                                method: 'POST',
                                body: formData
                            };
                            try {
                                const response = await fetch('https://deeplink-mail.herokuapp.com/message/link_notify', requestOptions);
                                console.log(response.status)
                            } catch (e) {
                                console.log(e)
                            }
                        }
                    }
                }
                handleConfirmWebinarClose()
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        const readImg = async () => {
            try {
                const storageRef = ref(storage, '/webinar_covers/' + confirmRow.webinarID)
                const url = await getDownloadURL(storageRef)
                if (url)
                    setImgCover(url)
                else
                    setImgCover('')
            }
            catch (e) {
                if (e.code === 'storage/object-not-found')
                    return
                console.log(e);
                setImgCover('');
            }
        }
        readImg();

    }, [confirmWebinarOpen])

    //Timestamp to date
    const StartTime = new Date(confirmRow.seconds * 1000);
    const EndTime = new Date((confirmRow.seconds + 60 * parseInt(confirmRow.duration)) * 1000)

    const breadcrumbs = [
        <Typography key="1" variant="body1" component="span" color="text.primary" onClick={() => handleConfirmWebinarClose()} sx={{ cursor: 'pointer', fontWeight: 600 }}>
            回上一頁
        </Typography>,
        <Typography key="2" color="text.secondary">
            {confirmRow.title}
        </Typography>
    ];

    return (
        confirmWebinarOpen &&
        <Box sx={{ flexGrow: 1 }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ paddingBottom: 1 }}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <Grid item xs={4} sm={8} md={8}>

                    <Stack spacing={2}>
                        <Box style={{
                            display: 'flex', justifyContent: 'center', position: 'relative',
                            width: '100%',
                            height: 0,
                            paddingBottom: '56.25%',
                        }}>
                            <div>
                                {imgCover && !imagePreview &&
                                    <img src={imgCover} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} alt="Avatar" />
                                }
                                {(!imgCover || imagePreview) &&
                                    <img src={imagePreview ? URL.createObjectURL(imagePreview) : webinarLoading} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} alt="Avatar" />
                                }
                                <Box sx={{
                                    position: 'absolute', left: 0, opacity: 0, transition: ".3s ease", width: '100%', height: '100%',
                                    backgroundColor: "rgba(0,0,0, 0.3)", "&:hover": {
                                        opacity: 1,
                                    },
                                }}>
                                    <Input accept=".jpg, .jpeg, .png" id="contained-button-file" multiple type="file" onChange={handleImgClick} />
                                    <IconButton style={{
                                        position: 'absolute', left: "45%", top: "45%", padding: 10,
                                    }} > <label htmlFor="contained-button-file" style={{
                                        width: '100%',
                                    }}> <PhotoIcon sx={{
                                        color: "white", fontSize: 50, "&:hover": {
                                            cursor: "pointer"
                                        },
                                    }} /></label>
                                    </IconButton>

                                </Box>
                            </div>
                        </Box>
                        <Box>
                            <div style={{ display: "felx", flexDirection: "column", flex: 1 }}>
                                <div style={{ paddingRight: 3 }}>

                                    {/* 舉辦題目 */}
                                    <div style={{ display: "flex", flexDirection: "confirmRow", justifyContent: "space-between" }}>
                                        <Typography variant="h5" gutterBottom align='left' sx={{ fontWeight: 700, marginLeft: "1px" }}>
                                            {confirmRow.title}
                                        </Typography>
                                    </div>

                                    <div style={{ display: "flex", flex: 1, alignItems: "center", paddingBottom: 10 }}>


                                        <div style={{ display: "flex", flex: 3, marginLeft: "1px", alignItems: "center" }}>
                                            {/* 舉辦時間 */}
                                            <AccessTimeIcon color="primary" />
                                            <Typography color="primary" display="block" >&nbsp;
                                                {moment(StartTime).format('YYYY/MM/DD HH:mm') + " ~ " + moment(EndTime).format('HH:mm')}&nbsp;&nbsp;
                                            </Typography>
                                            {/* 難易度 */}
                                            <AiFillSignal color="#4A74AA" />
                                            <Typography color="primary" display="block" >
                                                {confirmRow.level}
                                            </Typography>&nbsp;&nbsp;
                                            {/* 舉辦形式 */}
                                            <BiSelection color="#4A74AA" />
                                            <Typography color="primary" display="block" >
                                                {confirmRow.holdType}
                                            </Typography>


                                        </div>
                                    </div>

                                    <Divider />
                                    {width < 900 &&
                                        <>
                                            {/* 個人頭像 */}
                                            <div className={classes.div} >
                                                {
                                                    confirmRow.host.map((uid, index) => (
                                                        <Link to={"/profile/" + uid} key={index} style={{ margin: '10px 10px 10px 0', textDecoration: "none" }}>

                                                            <div style={{ display: 'flex', alignItems: "center", paddingRight: 2 }}>
                                                                {/* 頭像、姓名、訂閱者 */}
                                                                <Avatar uid={uid} />
                                                            </div>
                                                        </Link>
                                                    ))
                                                }
                                            </div>
                                            <Divider />
                                        </>
                                    }

                                    {/* 內容簡介 */}
                                    <CardContent sx={{ paddingLeft: 0, }}>


                                        <Title display="div" variant="h5">
                                            適用人群
                                        </Title>
                                        <Content display="div" variant="body1" >
                                            {confirmRow.target_people}
                                        </Content>


                                        <Title display="div" variant="h5">
                                            簡介
                                        </Title>
                                        <Content display="div" variant="body1" >
                                            {confirmRow.intro}
                                        </Content>
                                        <Title display="div" variant="h5">
                                            標籤
                                        </Title>
                                        <Label item={confirmRow} />

                                    </CardContent>

                                </div>
                            </div>
                        </Box>

                    </Stack>
                </Grid>

                <Grid item xs={4} sm={8} md={4} sx={{ display: { xs: "none", md: "block" } }}>
                    <Stack spacing={0} sx={{ marginBottom: 3 }}>
                        {PersonAvatar()}

                    </Stack>
                    {(confirmRow.resources.ref && confirmRow.resources.ref[0] || confirmRow.resources.url && confirmRow.resources.url[0]) && <FileCard webinarID={confirmRow.webinarID} resources={confirmRow.resources} />}
                    <Stack direction="row" sx={{ paddingTop: 3, px: 1 }}>
                        <Box sx={{ backgroundColor: '#7C7C7C', color: 'white', width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='subtitle2' component='span'>
                                會議連結
                            </Typography>
                        </Box>
                        <TextField id="input-with-sx" placeholder='複製會議連結' variant="outlined" size="small" value={meetURL} onChange={(e) => { setMeetURL(e.target.value) }}
                            sx={{
                                flex: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#7C7C7C',
                                        borderRadius: 0
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#7C7C7C',
                                        borderRadius: 0
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#7C7C7C',
                                        borderRadius: 0
                                    },
                                }
                            }} />
                    </Stack>

                    {imagePreview &&
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ paddingTop: 3, px: 1 }}
                        >
                            <Button variant="contained" color="error" onClick={() => setImagePreview('')} >
                                移除圖片
                            </Button>
                        </Stack>
                    }
                    <Stack sx={{ marginTop: 3, display: 'flex', alignItems: 'center' }}>
                        <MyButton variant="contained" onClick={() => handleOnSubmit()}>
                            確認送出
                        </MyButton>
                    </Stack>
                </Grid>
                <Snackbar open={openAlert.open} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={() => setOpenAlert({ open: false, status: "error", message: "" })}>
                    <Alert severity={openAlert.status} sx={{ width: '100%' }} >
                        {openAlert.message}
                    </Alert>
                </Snackbar>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}