import Chip from '@mui/material/Chip';

export default function Label({ item }) {
    const list = item.tags
    return (
        <div style={{ display: "flex", marginBottom: 10 }}>
            {list.map((i, index) => (
                <Chip key={index} label={i}
                    sx={{
                        backgroundColor: "#DDEFFE", color: (theme) => theme.palette.primary.main, marginRight: 1.5, padding: 1,
                        borderRadius: 2
                    }} />
            ))}
        </div>
    )
}