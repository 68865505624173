import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            phone: 300,
            sm: 600,
            md: 900,
            mdlg: 1050,
            lg: 1200,
            xl: 1536,
        },

    },
    // typography: {
    //     subtitle1: {
    //       fontSize: 12,
    //     },
    //     body1: {
    //       fontWeight: 500,
    //     },
    //     button: {
    //       fontStyle: 'italic',
    //     },
    //   },
    palette: {
        primary: {
            light: '#A0C4E6',
            main: '#4A74AA',
            dark: '#245298',
            darkText: '#1D3F73',
            // contrastText: '#',
        },
        secondary: {
            light: '#EC8867',
            main: '#F38500',
            dark: '#E07B00',
            // contrastText: '#',
        },
        // Used by getContrastText() to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});
