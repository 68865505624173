import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts"
import { Box, Card, CardContent, Typography } from '@mui/material'
import { db } from '../../../settings/firebaseConfig'
import { doc, getDoc } from "firebase/firestore"

export default function UserInterestedCategoryPieChart() {

    const [options, setOptions] = useState({ labels: [], legend: { position: 'bottom', horizontalAlign: 'center' } })
    const [series, setSeries] = useState([1, 2, 3, 4, 5, 6, 7, 8])

    const today = new Date()
    const defaultYear = today.getFullYear()
    const defaultMonth = today.getMonth()

    const [year, setYear] = useState(defaultYear)
    const [month, setMonth] = useState(defaultMonth)

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const [yearList, setYearList] = useState([])

    const date = year + "_" + String(month).padStart(2, 0)
    // console.log(date)

    useEffect(() => {
        const readData = async () => {
            const docRef = doc(db, "Interest", date);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setOptions((old) => ({ ...old, labels: Object.keys(docSnap.data()) }))
                setSeries(Object.values(docSnap.data()))
            } else {
                console.log("No such document!");
            }
        }
        readData()

    }, [year, month])

    useEffect(() => {
        const getYearList = () => {
            let temp = []
            for (let i = 2022; i <= defaultYear; i++)
                temp.push(i)
            setYearList(temp)
        }
        getYearList()
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ flex: '1 1 100%', fontWeight: 600, mb: 1 }} variant="h6" component="div" >
                            用戶感興趣的領域
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <select
                                value={year}
                                onChange={handleYearChange}
                                style={{ width: '60px', height: '25px', outline: 'none' }}
                            >{yearList.map((y) => (
                                <option value={y} key={y}>{y}</option>
                            ))}
                            </select>
                            年
                            <select
                                value={month}
                                onChange={handleMonthChange}
                                style={{ width: '40px', height: '25px', outline: 'none' }}
                            >
                                {year === defaultYear ?
                                    [...new Array(defaultMonth).keys()].map((m) => (
                                        <option value={m + 1} key={m + 1}>{m + 1}</option>
                                    ))
                                    :
                                    [...new Array(12).keys()].map((m) => (
                                        <option value={m + 1} key={m + 1}>{m + 1}</option>
                                    ))
                                }
                            </select>
                            月
                        </Box>
                    </Box>
                    <Chart options={options} series={series} type="donut" />
                </CardContent>
            </Card>
        </Box>
    )
}