import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts"
import { Box, Card, CardContent, Typography } from '@mui/material'
import { db } from '../../../settings/firebaseConfig'
import { getDocs, collection } from "firebase/firestore"

export default function WebinarCategoryPieChart() {

    const [options, setOptions] = useState({
        labels: [], legend: { position: 'bottom', horizontalAlign: 'center' }
    })
    const [series, setSeries] = useState([1, 2, 3, 4, 5, 6, 7, 8])

    useEffect(() => {
        const readData = async () => {
            const querySnapshot = await getDocs(collection(db, "Webinar"));
            const temp = []
            const temp2 = []

            querySnapshot.forEach((doc) => {
                doc.data().category !== undefined && temp.push(doc.data().category)
            })

            for (const option of [...new Set(temp)]) {
                const result = temp.filter(i => i === option).length;
                temp2.push(result)
            }
            setOptions((old) => ({ ...old, labels: [...new Set(temp)] }))
            setSeries(temp2)
        }
        readData()
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <Card>
                <CardContent>
                    <Typography sx={{ flex: '1 1 100%', fontWeight: 600, mb: 1 }} variant="h6" component="div" >
                        Webinar領域
                    </Typography>
                    <Chart options={options} series={series} type="donut" />
                </CardContent>
            </Card>
        </Box>
    )
}