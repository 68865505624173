import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'
import { db } from '../../../settings/firebaseConfig';
import { collection, getDocs, onSnapshot } from '@firebase/firestore';


export default function Statistics() {
    const [isLoading, setIsLoading] = useState(true)
    const [profileCount, setProfileCount] = useState(0)
    const [totalWebinarCount, setTotalWebinarCount] = useState(0)
    const [upcomingWebinarCount, setUpcomingWebinarCount] = useState(0)
    const [heldWebinarCount, setHeldWebinarCount] = useState(0)
    const [tempWebinarCount, setTempWebinarCount] = useState(0)

    const [holdProfileCount, setHoldProfileCount] = useState(0)

    const Item = [
        {
            text: '用戶總數',
            color: '#F53636',
            count: profileCount
        },
        {
            text: '曾舉辦Webinar用戶總數',
            color: '#2FA84F',
            count: holdProfileCount
        },
        {
            text: 'Webinar總數',
            color: '#367BF5',
            count: totalWebinarCount
        },
        {
            text: '即將舉辦Webinar數',
            color: '#367BF5',
            count: upcomingWebinarCount
        },
        {
            text: '已舉辦Webinar數',
            color: '#367BF5',
            count: heldWebinarCount
        },
        {
            text: '暫存Webinar數',
            color: '#367BF5',
            count: tempWebinarCount
        },
    ]

    useEffect(() => {
        const readProfileCount = async () => {
            try {
                setIsLoading(true)
                onSnapshot(collection(db, "Profile"), (querySnapshot) => {
                    let count = 0
                    let holdCount = 0

                    querySnapshot.forEach((doc) => {
                        count++
                        if (doc.data().webinars.length !== 0)
                            holdCount++
                    });
                    setProfileCount(count)
                    setHoldProfileCount(holdCount)
                })
                setIsLoading(false)

            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        }
        const readWebinarCount = async () => {
            try {
                setIsLoading(true)
                onSnapshot(collection(db, "Webinar"), (querySnapshot) => {
                    let date = new Date()
                    let totalCount = 0
                    let upcomingCount = 0
                    let heldCount = 0
                    let tempCount = 0;
                    querySnapshot.forEach((doc) => {
                        totalCount++
                        console.log()
                        let seconds = doc.data().time && doc.data().time.seconds
                        let startTime = new Date(seconds * 1000)

                        if (doc.data().status === "暫存")
                            tempCount++
                        else if (doc.data().status === "已確認" && startTime > date)
                            upcomingCount++
                        else
                            heldCount++
                    });
                    setTotalWebinarCount(totalCount)
                    setUpcomingWebinarCount(upcomingCount)
                    setHeldWebinarCount(heldCount)
                    setTempWebinarCount(tempCount)
                    setIsLoading(false)
                })
            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        }
        readProfileCount()
        readWebinarCount()
    }, [])
    return (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ flex: '1 1 100%', fontWeight: 600, mb: 1 }} variant="h6" component="div" >
                統計表
            </Typography>
            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {
                    Item.map((I, index) => (
                        <Grid item xs={2} sm={4} md={6} key={index}>

                            <Card sx={{ backgroundColor: '#F7F9FA', mb: 2, borderRadius: 5 }} elevation={0}>
                                <CardContent>
                                    <Stack>
                                        <Box sx={{ height: 40 }}>
                                            <Typography variant="body1" component="div">
                                                {I.text}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="h4" component="span" sx={{ display: 'flex', justifyContent: 'flex-end', color: I.color }}>
                                                {!isLoading ? I.count : <CircularProgress color="primary" size={35} />
                                                }
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
}
