import * as React from 'react';
import { useEffect } from 'react'
import { TextField, Box, Button, Typography, Dialog, DialogActions, DialogContent, InputAdornment } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DeepLinkLogo from '../../assets/img/common/DeeplinkLong.png'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';


export default function SignIn() {
    const [account, setAccount] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
    const [accountError, setAccountError] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')

    let navigate = useNavigate();

    const handleOnSubmit = async (account, password) => {
        setAccountError('')
        setPasswordError('')
        if (!account)
            setAccountError('請填寫此欄位')
        if (!password)
            setPasswordError('請填寫此欄位')
        else if (account.toLowerCase() !== 'deeplinkmanager0713@gmail.com')
            setError('帳號輸入錯誤！')
        else {
            try {
                const auth = getAuth();
                await signInWithEmailAndPassword(auth, 'deeplinkmanager0713@gmail.com', password)
                navigate('/manage')
            }
            catch (e) {
                if (e.code === 'auth/wrong-password') {
                    setError('密碼輸入錯誤！')
                }
                if (e.code === 'auth/too-many-requests') {
                    setError('您嘗試登入失敗次數過多，請稍後再試！')
                }
            }
        }

    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user)
                navigate('/manage')
        });
    }, [navigate])

    return (
        <>
            <Box sx={{ backgroundColor: (theme) => theme.palette.primary.main, height: 25, position: 'relative' }} />
            <Dialog
                open={true}
                hideBackdrop={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        boxShadow: 'none',
                        minWidth: 350
                    }
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img alt="deeplinklogo" src={DeepLinkLogo} width={200} />
                    <Typography color="primary.darkText" sx={{ mt: 3, fontSize: 30 }}>
                        後台管理系統
                    </Typography>
                </Box>

                <DialogContent>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            id="account"
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PermIdentityIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Email"
                            value={account}
                            onChange={(e) => setAccount(e.target.value)}
                            sx={{ flex: 1 }}
                            helperText={accountError}
                            onKeyPress={(e) => e.key === 'Enter' && handleOnSubmit(account, password)}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', mt: 3 }}>
                        <TextField
                            id="password"
                            variant="standard"
                            type="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon sx={{ transform: 'rotate(135deg)' }} />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{ flex: 1 }}
                            helperText={passwordError}
                            onKeyPress={(e) => e.key === 'Enter' && handleOnSubmit(account, password)}
                        />
                    </Box>
                    <Typography variant="body2" color="error" gutterBottom sx={{ marginTop: 3, flex: 1 }}>{error && error}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type="submit" disableRipple={true} disableElevation={true} sx={{ flex: 1 }} onClick={() => handleOnSubmit(account, password)}>登入</Button>
                </DialogActions>

            </Dialog>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body2" color="text.secondary" gutterBottom sx={{ position: 'absolute', bottom: 10 }}>Copyright © {new Date().getFullYear()} DeepLink</Typography>
            </Box>
        </>
    );
}