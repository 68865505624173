import * as React from 'react';
import { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography, styled, TextField, Button, Backdrop, CircularProgress, Breadcrumbs, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

//icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CardContent from '@mui/material/CardContent';
import { AiFillSignal } from 'react-icons/ai';
import { BiSelection } from 'react-icons/bi';
import Divider from '@mui/material/Divider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//components
import Label from "../../webinarContent/label";
import webinarLoading from "../../../assets/img/common/webinarLoading.png";
import PersonCard from '../../webinarContent/PersonCard'
import FileCard from '../../webinarContent/fileCard/Card';
import Avatar from "../../webinarContent/Avatar";

import moment from "moment"
import useWindowDimensions from '../../../customHook/useWindowDimension';
import { Link } from 'react-router-dom'

//firebase
import { db } from '../../../settings/firebaseConfig';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from '@firebase/firestore';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    div: {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap"
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'historyRow',
        },
    },
}));

const MyButton = styled(Button)({
    width: 100,
    boxShadow: '0px 2px 2px 0px #3F79B3',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: '#3F79B3',
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    borderRadius: 100,
    '&:hover': {
        backgroundColor: 'rgba(63, 121, 179, 0.1)',
        borderColor: '#FFFFFF',
        boxShadow: '0px 2px 2px 0px #3F79B3',
        color: '#3F79B3'
    }
});

const Title = styled(Typography)(() => ({
    color: "#212B36", fontWeight: 900, marginBottom: 12,

}));

const Content = styled(Typography)(() => ({
    color: "#212B36", marginBottom: 20, marginLeft: 2, whiteSpace: 'pre-line'

}));



export default function HistoryPage({ historyOpen, handleHistoryClose, historyRow }) {
    const { width } = useWindowDimensions()
    const classes = useStyles();
    const [imgCover, setImgCover] = useState('')
    const [videoURL, setVideoURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState({ open: false, status: "error", message: "" });

    const PersonAvatar = useCallback(() => <PersonCard host={historyRow.hostId} direction={width >= 1000 ? "row" : "column"} cardDirection="column" zoom="100%" />, [historyRow.hostId, width])

    const handleOnSubmit = async () => {
        try {
            if (!videoURL)
                setOpenAlert({ open: true, status: "error", message: "連結不可為空" })
            else {
                setIsLoading(true)

                const webinarRef = doc(db, "Webinar", historyRow.webinarID)
                await updateDoc(webinarRef, {
                    video: videoURL
                });
                handleHistoryClose()
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const storage = getStorage();
    useEffect(() => {
        const abortController = new AbortController()

        const readImg = async () => {
            try {
                const storageRef = ref(storage, '/webinar_covers/' + historyRow.webinarID)
                const url = await getDownloadURL(storageRef)
                if (url)
                    setImgCover(url)
            }
            catch (e) {
                console.log(e);
                setImgCover('');
            }
        }
        readImg();
        return () => {
            abortController.abort()
        }
    }, [])

    //Timestamp to date
    const StartTime = new Date(historyRow.seconds * 1000);
    const EndTime = new Date((historyRow.seconds + 60 * parseInt(historyRow.duration)) * 1000)

    const breadcrumbs = [
        <Typography key="1" variant="body1" component="span" color="text.primary" onClick={() => handleHistoryClose()} sx={{ cursor: 'pointer', fontWeight: 600 }}>
            回上一頁
        </Typography>,
        <Typography key="2" color="text.secondary">
            {historyRow.title}
        </Typography>
    ];

    return (
        historyOpen &&
        <Box sx={{ flexGrow: 1 }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ paddingBottom: 1 }}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <Grid item xs={4} sm={8} md={8}>

                    <Stack spacing={2}>
                        <Box style={{
                            display: 'flex', justifyContent: 'center', position: 'relative',
                            width: '100%',
                            height: 0,
                            paddingBottom: '56.25%',
                        }}>
                            {historyRow.video ?
                                <iframe style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} src={historyRow.video} title={historyRow.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                                :
                                <img alt={"faile to load"} src={imgCover === "" ? webinarLoading : imgCover} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
                            }
                        </Box>
                        <Box>
                            <div style={{ display: "felx", flexDirection: "column", flex: 1 }}>
                                <div style={{ paddingRight: 3 }}>

                                    {/* 舉辦題目 */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <Typography variant="h5" gutterBottom align='left' sx={{ fontWeight: 700, marginLeft: "1px" }}>
                                            {historyRow.title}
                                        </Typography>
                                    </div>

                                    <div style={{ display: "flex", flex: 1, alignItems: "center", paddingBottom: 10 }}>


                                        <div style={{ display: "flex", flex: 3, marginLeft: "1px", alignItems: "center" }}>
                                            {/* 舉辦時間 */}
                                            <AccessTimeIcon color="primary" />
                                            <Typography color="primary" display="block" >&nbsp;
                                                {moment(StartTime).format('YYYY/MM/DD HH:mm') + " ~ " + moment(EndTime).format('HH:mm')}&nbsp;&nbsp;
                                            </Typography>
                                            {/* 難易度 */}
                                            <AiFillSignal color="#4A74AA" />
                                            <Typography color="primary" display="block" >
                                                {historyRow.level}
                                            </Typography>&nbsp;&nbsp;
                                            {/* 舉辦形式 */}
                                            <BiSelection color="#4A74AA" />
                                            <Typography color="primary" display="block" >
                                                {historyRow.holdType}
                                            </Typography>


                                        </div>
                                    </div>

                                    <Divider />
                                    {width < 900 &&
                                        <>
                                            {/* 個人頭像 */}
                                            <div className={classes.div} >
                                                {
                                                    historyRow.host.map((uid, index) => (
                                                        <Link to={"/profile/" + uid} key={index} style={{ margin: '10px 10px 10px 0', textDecoration: "none" }}>

                                                            <div style={{ display: 'flex', alignItems: "center", paddingRight: 2 }}>
                                                                {/* 頭像、姓名、訂閱者 */}
                                                                <Avatar uid={uid} />
                                                            </div>
                                                        </Link>
                                                    ))
                                                }
                                            </div>
                                            <Divider />
                                        </>
                                    }

                                    {/* 內容簡介 */}
                                    <CardContent sx={{ paddingLeft: 0, }}>


                                        <Title display="div" variant="h5">
                                            適用人群
                                        </Title>
                                        <Content display="div" variant="body1" >
                                            {historyRow.target_people}
                                        </Content>


                                        <Title display="div" variant="h5">
                                            簡介
                                        </Title>
                                        <Content display="div" variant="body1" >
                                            {historyRow.intro}
                                        </Content>
                                        <Title display="div" variant="h5">
                                            標籤
                                        </Title>
                                        <Label item={historyRow} />

                                    </CardContent>

                                </div>
                            </div>
                        </Box>

                    </Stack>
                </Grid>

                <Grid item xs={4} sm={8} md={4} sx={{ display: { xs: "none", md: "block" } }}>
                    <Stack spacing={0} sx={{ marginBottom: 3 }}>
                        {PersonAvatar()}

                    </Stack>
                    {!historyRow.video &&
                        <>
                            <Stack direction="row" sx={{ paddingTop: 3, px: 1 }}>
                                <Box sx={{ backgroundColor: '#7C7C7C', color: 'white', width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant='subtitle2' component='span'>
                                        影片連結
                                    </Typography>
                                </Box>
                                <TextField id="input-with-sx" placeholder='複製影片連結' variant="outlined" size="small" value={videoURL} onChange={(e) => { setVideoURL(e.target.value) }}
                                    sx={{
                                        flex: 1,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#7C7C7C',
                                                borderRadius: 0
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#7C7C7C',
                                                borderRadius: 0
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#7C7C7C',
                                                borderRadius: 0
                                            },
                                        }
                                    }} />
                            </Stack>
                            <Stack sx={{ marginTop: 3, display: 'flex', alignItems: 'center' }}>
                                <MyButton variant="contained" onClick={() => handleOnSubmit()}>
                                    確認送出
                                </MyButton>
                            </Stack>
                        </>
                    }
                    {(historyRow.resources.ref && historyRow.resources.ref[0] || historyRow.resources.url && historyRow.resources.url[0]) && <FileCard webinarID={historyRow.webinarID} resources={historyRow.resources} />}

                </Grid>

            </Grid>
            <Snackbar open={openAlert.open} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={() => setOpenAlert({ open: false, status: "error", message: "" })}>
                <Alert severity={openAlert.status} sx={{ width: '100%' }} >
                    {openAlert.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}