import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { BiLockAlt, BiLockOpenAlt } from 'react-icons/bi';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../Context'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import { RiFileEditFill } from 'react-icons/ri';
// import { useHistory, } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ImFileEmpty } from 'react-icons/im';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditDialog from './EditDialog';
import { bytesConvert, getWebinarFiles, downloadFile } from './func';
import { db } from '../../../settings/firebaseConfig'
import { updateDoc, doc, collection, getDocs } from 'firebase/firestore';

const Title = styled(Typography)(({ theme }) => ({
    color: "#212B36", fontWeight: 900, marginBottom: 12, marginLeft: 8

}));

const BootstrapButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '10px 12px',
    marginTop: 15,
    border: '1px solid',
    borderRadius: 9,
    lineHeight: 3,
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    width: '100%',

    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        boxShadow: 'none',
    },

}));




export default function FileCard({ webinarID, resources, handleLoginBounceOpen, host }) {
    const authContext = useContext(Context)
    const [metaData, setMetaData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    // const history = useHistory();
    const navigate = useNavigate();

    useEffect(() => {
        getWebinarFiles(webinarID, setMetaData)
    }, [editOpen])



    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                <Title display="div" variant="h5">
                    檔案
                </Title>
                {host.indexOf(authContext.userStatus.uid) > -1 && <UploadFileIcon color="primary" sx={{ marginRight: 1, cursor: "pointer" }} onClick={() => setEditOpen(true)} />}
            </Box>
            <Card sx={{ backgroundColor: '#F8F8F8', boxShadow: 'none', border: "1px solid transparent", borderRadius: 4, padding: 2 }}>
                <CardContent>
                    {!metaData.length && !resources.length ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ImFileEmpty size="20px" color="gray" align="center" />
                            <Typography sx={{ fontSize: 14, fontWeight: "bold", margin: 1 }} color="text.secondary" >
                                此分享會尚無檔案
                            </Typography>
                        </Box>
                        :
                        <>
                            {metaData.map((item, index) =>
                                <div key={index} style={{
                                    display: "flex", backgroundColor: "white", padding: 10, alignItems: "center",
                                    borderBottom: "0.8px solid #d7dce0",
                                    cursor: "pointer",
                                    justifyContent: "space-between"

                                }}
                                    onClick={() => authContext.userStatus.isSignIn ? downloadFile(item.itemRef) : handleLoginBounceOpen()}
                                >
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        {authContext.userStatus.isSignIn ? <BiLockOpenAlt size="25px" /> : <BiLockAlt color="#919EAB" size="30px" />}
                                        <div style={authContext.userStatus.isSignIn ? {} : {
                                            fontWeight: 700, marginTop: 0.5, marginInline: 0.5, color: "#919EAB"
                                        }}>
                                            {item.name.length > 25 ? item.name.substr(0, 25) + '...' : item.name}

                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        <Typography color="text.secondary" variant="body2" sx={{ marginRight: 1 }}> {bytesConvert(item.size)}</Typography>
                                        <FileDownloadIcon />
                                    </div>
                                </div>
                            )}
                            {resources.map((url, index) =>
                                <div key={index} style={{
                                    display: "flex", backgroundColor: "white", padding: 10, alignItems: "center",
                                    borderBottom: "0.8px solid #d7dce0",
                                    cursor: "pointer", justifyContent: "space-between"

                                }}
                                    onClick={() => authContext.userStatus.isSignIn ? window.open(url) : handleLoginBounceOpen()}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {authContext.userStatus.isSignIn ? <BiLockOpenAlt size="25px" /> : <BiLockAlt color="#919EAB" size="30px" />}
                                        <div style={authContext.userStatus.isSignIn ? {} : {
                                            fontWeight: 700, marginTop: 0.5, marginInline: 0.5, color: "#919EAB"
                                        }}

                                        >
                                            {"檔案連結 - " + (index + 1)}
                                        </div>

                                    </div>
                                    <LinkIcon />
                                </div>
                            )}
                            {!authContext.userStatus.isSignIn &&
                                <CardActions sx={{ padding: 0 }}>
                                    <BootstrapButton variant="contained" disableRipple onClick={() => navigate('/signIn')}>
                                        <Typography sx={{ color: "white", fontWeight: 700 }}>{authContext.userStatus.isSignIn ? "點擊以查看檔案" : "登入以查看檔案"}</Typography>
                                    </BootstrapButton>
                                </CardActions>
                            }
                        </>
                    }
                </CardContent>
            </Card >
            {editOpen && <EditDialog {...{ editOpen, setEditOpen, metaData, webinarID, resources }} />}
        </Box>

    )
}