import { Routes, Route } from 'react-router-dom';
import './assets/styles/app.css';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

import Manage from './components/manage';
import SignIn from './components/home/SignIn';

export default function App() {

  return (
    <Routes>
      <Route exact path='/manage' element={<Manage />} />
      <Route exact path='/' element={<SignIn />} />
    </Routes>
  );
}