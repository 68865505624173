import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ReactComponent as LoadingDot } from "./loadingDot.svg";


const Bar = styled(Box)`
  height: 3em;
  background-color: #ededed;
  border-radius: 1.5em;
  margin: 1em;
  box-sizing: border-box;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  ::before {
    content: attr(data-label);
    position: absolute;
    left: 0.5em;
    box-sizing: border-box;
    background-color: ${(props) => props.color};
    width: calc(var(--width, 0) * 1%);
    min-width: 2%;
    max-width: calc(100% - 1em);
    padding: 0.3em 0.45em;
    border-radius: 1.5em;
    transition: all 700ms ease;
  }
`;

const Container = styled(Box)`
  display: flex;
  height:15vh;
  width:50vw;
  @media (max-width: 600px){
    width:80vw;
  }
  flex-direction: column;
  // align-content: center;
  // justify-content: center;
`;
const Info = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0em 2em;
  color: white;
`;

export default function LoadingBar({ barInfo: { progress, index, total } }) {
  console.log("progress" + progress)
  return (
    <Container >
      <Info style={{ flex: 1 }}>
        <span style={{ flex: 1, display: "flex" }}></span>
        <span style={{ flex: 1, display: "flex", justifyContent: "center" }}>Loading
          <LoadingDot className="Dot-svg " alt="svg"
            style={{
              height: "40px", width: "40px",
              alignSelf: "center", marginTop: -5
            }} />
        </span>
        <span style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>{`${index}/${total}`}</span>
      </Info>
      <Bar
        data-label={`${Math.floor(progress)}%`}
        color={progress > 60 ? "#4a74aa" : "#FFA600"}
        style={{ "--width": progress, marginTop: -30 }}
      />
    </Container>
  )
}