import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SentedWebinar from './dashBoard/SentedWebinar'
import Statistics from './dashBoard/Statistics'
import Manage from './dashBoard/Manage';
import UserInterestedCategoryPieChart from './dashBoard/UserInterestedCategoryPieChart'
import WebinarCategoryPieChart from './dashBoard/WebinarCategoryPieChart'
import ConfirmPage from './dashBoard/ConfirmPage';

export default function DashBoard({ handleConfirmWebinarOpen, handleConfirmWebinarClose, confirmWebinarOpen, confirmRow }) {

    return (
        <Box sx={{ flexGrow: 1 }}>
            {confirmWebinarOpen ?
                <ConfirmPage {...{ confirmWebinarOpen, handleConfirmWebinarClose, confirmRow }} />
                :
                <>
                    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ paddingTop: 1 }}>
                        <Grid item xs={4} sm={5} md={8.5} >
                            <Box pl={{ xs: 1.5, phone: 2, sm: 1 }} pr={{ xs: 1.5, phone: 2, sm: 1 }}>
                                {!confirmWebinarOpen &&
                                    <SentedWebinar {...{ handleConfirmWebinarOpen }} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={3} md={3.5} >
                            <Box pl={{ xs: 1.5, phone: 2, sm: 1 }} pr={{ xs: 1.5, phone: 2, sm: 1 }} pt={{ xs: 1.5, phone: 3, sm: 0 }}>
                                <Statistics />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 16 }} sx={{ paddingTop: 1 }}>
                        <Grid item xs={4} sm={4} md={6} lg={5}>
                            <Box pl={{ xs: 1.5, phone: 3, sm: 1 }} pr={{ xs: 1.5, phone: 2, sm: 1 }}>
                                <UserInterestedCategoryPieChart />
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={5} >
                            <Box pl={{ xs: 1.5, phone: 3, sm: 1 }} pr={{ xs: 1.5, phone: 2, sm: 1 }} pt={{ xs: 1.5, phone: 3, sm: 0 }} >
                                <WebinarCategoryPieChart />
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12} lg={6}>
                            <Box pt={{ xs: 1.5, phone: 3, sm: 2 }}>
                                <Manage />
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }
        </Box >
    );
}
