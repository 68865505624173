import { useState, useEffect } from 'react';

import { Toolbar, Typography, CssBaseline, Box, styled, AppBar, Stack, Button, Tabs, Tab } from "@mui/material";

import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import DeepLinkLogo from "../../assets/img/common/DeeplinkLong.png";
import DashBoard from './DashBoard';
import History from './History';
import HistoryPage from './history/HistoryPage';
import useWindowDimensions from '../../customHook/useWindowDimension';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

const MyTab = styled(Tab)(({ theme }) => ({
    color: 'black', padding: 1, borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(160, 196, 230, 0.2)",
        color: theme.palette.primary.main
    },
    "&:focus": {
        color: theme.palette.primary.dark
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ManageHome() {
    const [value, setValue] = useState(0);
    const [confirmWebinarOpen, setConfirmWebinarOpen] = useState(false)
    const [historyOpen, setHistoryOpen] = useState(false)
    const [confirmRow, setConfirmRow] = useState({})
    const [historyRow, setHistoryRow] = useState({})

    const handleConfirmWebinarOpen = (confirmRow) => {
        setConfirmWebinarOpen(true)
        setConfirmRow(confirmRow)
    }
    const handleConfirmWebinarClose = () => {
        setConfirmWebinarOpen(false)
    }

    const handleHistoryOpen = (historyRow) => {
        setHistoryOpen(true)
        setHistoryRow(historyRow)
    }
    const handleHistoryClose = () => {
        setHistoryOpen(false)
    }

    const { width } = useWindowDimensions()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let navigate = useNavigate();
    const logOut = async () => {
        try {
            const auth = getAuth();
            await signOut(auth)
            navigate('/')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (!user)
                navigate('/')
        });
    }, [navigate])

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" color="inherit">
                <Toolbar sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flex: 2 }}>

                        <Stack direction={width > 700 ? "row" : "column"} spacing={width > 700 ? 2 : 0}>
                            <Link to="/" style={{ marginRight: 10, textDecoration: 'none', color: '#245298' }}>
                                <img alt="deeplinklogo" src={DeepLinkLogo} width={110} />
                            </Link>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography component="span" variant={width > 660 ? "h6" : "body1"} sx={{ paddingTop: width > 660 ? 1 : 0 }} color="text.secondary">後台管理系統</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{ flex: 3 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                }
                            }}
                        >
                            <MyTab label="儀表板" {...a11yProps(0)} disableRipple={true} disableElevation={true} />
                            <MyTab label="回顧歷史" {...a11yProps(1)} disableRipple={true} disableElevation={true} />
                        </Tabs>
                    </Box>
                    <Box sx={{ justifyContent: 'flex-end' }}>
                        <Button disableRipple={true} disableElevation={true} onClick={logOut}>登出</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ marginTop: 6 }}>
                <TabPanel value={value} index={0}>
                    <DashBoard {...{ handleConfirmWebinarOpen, handleConfirmWebinarClose, confirmWebinarOpen, confirmRow }} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {!historyOpen ?
                        <History {...{ handleHistoryOpen }} />
                        :
                        <HistoryPage {...{ historyOpen, handleHistoryClose, historyRow }} />
                    }
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
            </Box>
        </>
    );
}
