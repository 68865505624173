import { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar';
import { Typography, Stack } from '@mui/material'
import { db } from '../../settings/firebaseConfig';
import { doc, getDoc } from '@firebase/firestore';

export default function Avatar_Person({ uid }) {
    const [profile, setProfile] = useState({})

    useEffect(() => {
        let abortController = new AbortController();

        const readData = async () => {
            const profileRef = doc(db, 'Profile', uid);
            const profileSnap = await getDoc(profileRef);
            setProfile({ displayName: profileSnap.data().displayName, photoURL: profileSnap.data().photoURL, fans: profileSnap.data().fans })
        }
        readData()
        return () => {
            abortController.abort();
        }

    }, [uid])

    return (
        <>
            <Avatar alt={profile.photoURL} src={profile.photoURL ? profile.photoURL : "/static/images/avatar/1.jpg"} style={{ width: 50, height: 50 }} />
            <div style={{ marginLeft: 10, marginTop: 3 }}>
                <Stack>
                    <Typography variant="body1" color="black">
                        {profile.displayName}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {profile.fans && profile.fans.length} 位訂閱者
                    </Typography>
                </Stack>
            </div>
        </>

    )
}