import { createContext } from "react";

export const userData = {
    isSignIn: false
};

export const Context = createContext({

    userStatus: userData,
    setUserStatus: (newStatus) => {
        this.userStatus = newStatus;
    },

});
