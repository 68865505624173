//v8.10.0
// import firebase from "firebase";

// const firebaseApp = firebase.initializeApp({
//     apiKey: "AIzaSyCW7MGrOQzrioolMx2iSdfU2EJZ795esWY",
//     authDomain: "deeplinkim.firebaseapp.com",
//     projectId: "deeplinkim",
//     storageBucket: "deeplinkim.appspot.com",
//     messagingSenderId: "1070949872768",
//     appId: "1:1070949872768:web:070d9dbbca1805b33a6265",
//     measurementId: "G-GTR4HJM4Q4"
// });
// if (!firebase.apps.length) {

//     firebase.initializeApp(firebaseApp);

// }
// const db = firebase.firestore();
// const auth = firebase.auth();

// export { db, auth, };

//v9.1.3
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "@firebase/storage";

//online config
const config = {
    apiKey: "AIzaSyDPcKX33qYKhq3Lk2VQ4mIGnUpvsMgGLoY",
    authDomain: "deeplink-4a41c.firebaseapp.com",
    projectId: "deeplink-4a41c",
    storageBucket: "deeplink-4a41c.appspot.com",
    messagingSenderId: "710788246502",
    appId: "1:710788246502:web:8f24c6f44a42c6afc44427"
};

//test config
const testConfig = {
    apiKey: "AIzaSyDutx5Gdo-7PjK-PN4t-2XJCUQPl4Vzg10",
    authDomain: "deeplink-test-e2241.firebaseapp.com",
    projectId: "deeplink-test-e2241",
    storageBucket: "deeplink-test-e2241.appspot.com",
    messagingSenderId: "942628232379",
    appId: "1:942628232379:web:241054b3f343e512e61d9e",
    measurementId: "G-4MBR6BD84C"
};

const firebaseApp = initializeApp(config);
const initial = () => initializeApp(config);
const db = getFirestore();
const storage = getStorage(firebaseApp, "gs://deeplink-4a41c.appspot.com")


export { db, initial, storage };

