import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { db } from '../../settings/firebaseConfig';
import { getDoc, doc, updateDoc } from '@firebase/firestore';
import { Context } from '../../Context'
import { Link } from 'react-router-dom'

export default function PersonCard({ host, direction, cardDirection, zoom, handleLoginBounceOpen }) {
    const authContext = useContext(Context)
    const [hostProfile, setHostProfile] = useState([])
    const [loginUser, setLoginUser] = useState({ subscribe: [] })

    //handle Subscribe
    const handleOnSubscribe = async (hostId) => {
        try {
            if (authContext.userStatus.isSignIn) {

                //get the host index of the hostProfile
                const hostUserIndex = () => {
                    for (let i = 0; i < hostProfile.length; i++) {
                        if (hostProfile[i].id === hostId) {
                            return i;
                        }
                    }
                }
                const hostIndex = hostUserIndex()

                //delete hostid in loginUser's subscribe & delete loginUser's id in hostUser's fans
                if (loginUser.subscribe.includes(hostId)) {

                    let loginUserIndex = loginUser.subscribe.indexOf(hostId)
                    loginUser.subscribe.splice(loginUserIndex, 1)

                    let hostUserIndex = hostProfile[hostIndex].fans.indexOf(authContext.userStatus.uid)
                    hostProfile[hostIndex].fans.splice(hostUserIndex, 1);

                    setLoginUser((old) => ({ ...old, subscribe: loginUser.subscribe }))
                    setHostProfile([...hostProfile])
                }

                //add hostid in subscribe
                else {
                    loginUser.subscribe.push(hostId)
                    hostProfile[hostIndex].fans.push(authContext.userStatus.uid);
                    setLoginUser((old) => ({ ...old, subscribe: loginUser.subscribe }))
                    setHostProfile([...hostProfile])
                }

                //update DB
                const loginUserProfileRef = doc(db, 'Profile', authContext.userStatus.uid);
                const hostProfileRef = doc(db, 'Profile', hostId);

                await updateDoc(loginUserProfileRef, {
                    subscribe: loginUser.subscribe
                });
                await updateDoc(hostProfileRef, {
                    fans: hostProfile[hostIndex].fans
                });
            }
            else {
                handleLoginBounceOpen()
            }
        } catch (e) {
            console.log(e)
        }
    }

    // read login user
    useEffect(() => {
        const readLoginUser = async () => {
            try {
                if (authContext.userStatus.isSignIn) {
                    const profileRef = doc(db, 'Profile', authContext.userStatus.uid);
                    const profilesDoc = await getDoc(profileRef);
                    setLoginUser({ subscribe: profilesDoc.data().subscribe })
                }
            } catch (e) {
                console.log(e)
            }
        }
        readLoginUser()
    }, [authContext.userStatus.isSignIn])

    //read host
    useEffect(() => {

        const readHostData = async () => {
            try {
                const waitEach = async () => {
                    const temp = []
                    for (var i = 0; i < host.length; i++) {
                        const profileRef = doc(db, "Profile", host[i]);
                        const profilesDoc = await getDoc(profileRef);

                        if (profilesDoc.exists()) {
                            temp.push({ id: host[i], displayName: profilesDoc.data().displayName, photoURL: profilesDoc.data().photoURL, intro: profilesDoc.data().intro, tags: profilesDoc.data().tags, subscribe: profilesDoc.data().subscribe, fans: profilesDoc.data().fans })
                        }
                    }
                    return temp;
                }
                const temp = await waitEach();
                setHostProfile([...temp])
            }
            catch (e) {
                console.log(e)
            }
        }
        readHostData()
    }, [host])

    return (
        <Stack direction={cardDirection} sx={{ zoom: zoom, flexWrap: 'wrap' }} >
            {hostProfile.map((item, index) => (
                <Card key={index} sx={{ backgroundColor: '#F8F8F8', marginBlockEnd: zoom !== "100%" ? 1 : -1.6, marginRight: 1, borderRadius: 3, minWidth: 200 }} elevation={0} >
                    <CardContent sx={{
                        "&:last-child": {
                            paddingBottom: 1.5
                        }
                    }}>
                        <Stack direction={direction} spacing={2} >
                            <Stack justifyContent="flex-start" alignItems="center" direction="row">
                                <Link to={"/profile/" + item.id} style={{ textDecoration: "none" }}>
                                    <Avatar alt={item.photoURL} src={item.photoURL ? item.photoURL : "/static/images/avatar/1.jpg"} sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center' }} />
                                </Link>
                            </Stack>
                            <Stack spacing={0} sx={{ flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>
                                <Link to={"/profile/" + item.id} style={{ textDecoration: "none", color: 'black' }}>
                                    <Typography variant="body1" component="h2">
                                        {item.displayName}
                                    </Typography>
                                </Link>
                                <Typography variant="caption" component="h2" color="gray" >
                                    {item.intro && item.intro.slice(0, 20)}
                                </Typography>
                                <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {item.tags && item.tags.map((t, index) => (
                                        index < 2 &&
                                        <Chip label={'# ' + t} size="small" key={index}
                                            sx={{ backgroundColor: "#DDEFFE", color: (theme) => theme.palette.primary.main, marginRight: 1, marginTop: 0.5 }} />
                                    ))}
                                </Stack>
                            </Stack>

                            {authContext.userStatus.isSignIn && item.id === authContext.userStatus.uid ?
                                null :
                                <Stack justifyContent="center">
                                    {item.id && loginUser.subscribe && loginUser.subscribe.includes(item.id) ?
                                        <Button variant="contained" size="small" color="secondary" sx={{ color: 'white', fontSize: '8px', borderRadius: 10 }} onClick={() => handleOnSubscribe(item.id)}>追蹤中</Button>
                                        :
                                        <Button variant="outlined" size="small" color="secondary" sx={{ color: 'secondary', fontSize: '8px', borderRadius: 10 }} onClick={() => handleOnSubscribe(item.id)}>追蹤</Button>
                                    }
                                </Stack>
                            }
                        </Stack>

                    </CardContent>

                </Card>
            ))
            }
        </Stack >
    );
}
