import * as React from 'react';
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment';

import { db } from '../../../settings/firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'title',
        label: '標題',
    },
    {
        id: 'host',
        label: '主講者',
    },
    {
        id: 'holdType',
        label: '分享形式',
    },
    {
        id: 'category',
        label: '領域',
    },
    {
        id: 'submitTime',
        label: '上傳時間',
    },
    {
        id: 'seconds',
        label: '舉辦時間',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding='normal'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{ fontWeight: 600 }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id &&
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function SentedWebinar({ handleConfirmWebinarOpen }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('submitTime');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const readData = async () => {
        const q = query(collection(db, "Webinar"), where("status", "==", "已送出"))
        const querySnapshot = await getDocs(q);

        const temp = []
        querySnapshot.forEach((doc) => {
            temp.push({
                ...doc.data(),
                webinarID: doc.id,
                hostId: doc.data().host,
                submitTime: doc.data().submitTime.seconds,
                seconds: doc.data().time.seconds,
                duration: doc.data().time.duration
            })
        })
        return temp;
    }

    const readHost = async (tempWebinar) => {
        for (let t of tempWebinar) {
            let temp = []
            for (let hostId of t.host) {
                const profileRef = doc(db, "Profile", hostId);
                const profilesDoc = await getDoc(profileRef);
                temp.push(profilesDoc.data().displayName)
            }
            t.host = temp
        }
        setRows(tempWebinar)
        setIsLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()

        const wait = async () => {
            const tempWebinar = await readData()
            readHost(tempWebinar)
        }
        wait()

        return () => {
            abortController.abort()
        }
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ flex: '1 1 100%', fontWeight: 600, mb: 1 }} variant="h6" component="div" >
                已送出的Webinars
            </Typography>
            <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ minHeight: 320 }}>
                    {!isLoading ?

                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {rows.length <= 0 &&
                                    <Box>
                                        <Typography>尚無資料</Typography>
                                    </Box>
                                }

                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.title);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                sx={{ "&:hover": { backgroundColor: '#EFF7FF !important', cursor: 'pointer' } }}
                                                onClick={() => handleConfirmWebinarOpen(row)}
                                            >
                                                <TableCell align="left">{row.title}</TableCell>
                                                <TableCell align="left">{row.host.length > 1 ? row.host.slice(0, 2) + '...' : row.host}</TableCell>
                                                <TableCell align="left">{row.holdType}</TableCell>
                                                <TableCell align="left">{row.category}</TableCell>
                                                <TableCell align="left">{moment(new Date(row.submitTime * 1000)).format('YYYY/MM/DD HH:mm')}</TableCell>
                                                <TableCell align="left">
                                                    {moment(new Date(row.seconds * 1000)).format('YYYY/MM/DD HH:mm')}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 325 }}>
                            <CircularProgress color="primary" />
                        </Box>
                    }
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="每頁資料數："
                    labelDisplayedRows={({ from, to, count }) => { return `第 ${from} 到 ${to} 筆，共 ${count !== -1 ? count : `more than ${to}`} 筆`; }}
                />
            </Paper>
        </Box>
    );
}
