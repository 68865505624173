import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCloudUpload, AiFillFileWord, AiFillFilePdf, AiFillFilePpt } from 'react-icons/ai';
import { GrDocumentTxt } from 'react-icons/gr';
import { ImFileEmpty } from 'react-icons/im';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MuiAlert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import LoadingBar from "./LoadingBar";
import { updateWebinarFiles } from './func';


const BootstrapButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 15,
    padding: '5px 5px',
    border: '1px solid',
    borderRadius: 9,
    lineHeight: 2,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    width: '100%',
    color: "white",

    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        boxShadow: 'none',
    },

}));

export const CusBox = styled(Box)(({ theme, }) => ({
    paddingLeft: 80, paddingRight: 80, paddingBottom: 0,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 80, paddingRight: 80,
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: 50, paddingRight: 50,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 20, paddingRight: 20,
    },
}));


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#4A74AA',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#4A74AA',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    justifyContent: 'center'
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const filetype = (file) => {
    switch (file[file.length - 1]) {
        case 'pdf':
            return <AiFillFilePdf size="25px" color="#245298" />;

        case 'pptx':
            return <AiFillFilePpt size="25px" color="#245298" />;
        case 'docx':
            return <AiFillFileWord size="25px" color="#245298" />;
        case 'txt':
            return <GrDocumentTxt size="25px" color="#245298" />;

        default:
            return <AiFillFileWord size="25px" color="#245298" />;
    }
}

const bytesConvert = (bytes) => {

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

}



export default function EditDialog({ editOpen, setEditOpen, metaData, webinarID, resources }) {
    const [alert, setAlert] = React.useState({ open: false, message: "" })
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, } = useDropzone({ accept: ' .pdf, .pptx, .docx, .txt' });
    const [oldFiles, setOldFiles] = React.useState([]);
    const [newFiles, setNewFiles] = React.useState([]);
    const [urlArray, setUrlArray] = React.useState([]);
    const [loadings, setLoadings] = React.useState(false);
    const [barInfo, setBarInfo] = React.useState({
        progress: 0,
        index: 0,
        total: 0
    });
    const deleteRef = React.useRef([]);
    const handleAlertOpen = () => {
        setAlert({ open: true, message: "檔案上限為20MB" })
    };

    const deleteOldFile = (index, itemRef) => {
        oldFiles.splice(index, 1);
        setOldFiles([...oldFiles]);
        deleteRef.current.push(itemRef);

    }
    const deleteNewFile = (index) => {
        console.log('delete')
        newFiles.splice(index, 1);
        setNewFiles([...newFiles])
    }
    const saveToDB = async () => {
        try {
            setLoadings(true)
            await updateWebinarFiles({ webinarID, deleteRef, newFiles, urlArray, setBarInfo })
            setTimeout(() => {
                setLoadings(false)
                setEditOpen(false)
            }, 800)

        } catch (e) {
            console.log(e)
            setLoadings(false)
            setAlert({ open: true, message: "編輯失敗" })
        }
    }
    const style = React.useMemo(() => ({
        ...baseStyle,
        height: "100%"
    }), []);

    React.useEffect(() => {
        if (acceptedFiles.length > 0) {
            const oldBytes = oldFiles.reduce((totalBytes, file) => {
                return totalBytes + file.size;
            }, 0)
            const newBytes = acceptedFiles.reduce(
                (totalbytes, file) => {
                    return totalbytes + file.size
                }, 0
            );
            if (oldBytes + newBytes <= 20000000) {
                setNewFiles(prev => [...prev, ...acceptedFiles]);
            } else {
                handleAlertOpen();
            }

        }

    }, [acceptedFiles])

    React.useEffect(() => {
        setOldFiles([...metaData])
        setNewFiles([])
        deleteRef.current = [];
        if (!resources.length) {
            setUrlArray([""])
        } else {
            setUrlArray([...resources])
        }

    }, [editOpen])

    return (
        <div>
            <Dialog
                fullScreen
                open={editOpen}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setEditOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            編輯檔案
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => saveToDB()}>
                            儲存
                        </Button>
                    </Toolbar>
                </AppBar>
                <CusBox className="container" style={{ marginTop: 50, marginBottom: 10, height: "50%", display: "flex", alignItems: "center", justifyContent: "center", }} >
                    <div {...getRootProps({ style })}>
                        <AiOutlineCloudUpload color="#4A74AA" size="40px" />
                        <input {...getInputProps()} />
                        {!isDragActive && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="body1" sx={{ marginBottom: 0, }}>拖曳檔案至此</Typography>
                                <Typography variant="body1" sx={{ marginBottom: 0, }}>(僅接受.pdf, .pptx, .docx, .doc, .txt)</Typography>
                                <p>或</p>
                                <BootstrapButton>點擊以選擇檔案</BootstrapButton>
                            </div>
                        )}
                    </div>

                </CusBox>
                <CusBox >
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', }}>
                        檔案
                    </div>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                        {!oldFiles.length && !newFiles.length ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ImFileEmpty size="30px" color="gray" />
                                <Typography variant="body2" sx={{ marginBottom: 3, marginTop: 1, fontWeight: 'bold', color: "gray" }}>尚無上傳檔案</Typography>
                            </div>
                            :
                            <div>
                                {/* 原有的 */}
                                {oldFiles.map((file, index) => (
                                    <div key={index}>
                                        <ListItem sx={{ "&.MuiListItem-root": { paddingLeft: 0 } }}>
                                            <ListItemAvatar>
                                                {filetype(file.name.split('.'))}
                                            </ListItemAvatar>
                                            <ListItemText primary={file.name} secondary={bytesConvert(file.size)} sx={{ color: "#212B36" }} />
                                            <IconButton edge="end" aria-label="comments" onClick={() => deleteOldFile(index, file.itemRef)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                                {/* 新增的 */}
                                {newFiles.map((file, index) => (
                                    <div key={index}>
                                        <ListItem sx={{ "&.MuiListItem-root": { paddingLeft: 0 } }}>
                                            <ListItemAvatar>
                                                {filetype(file.name.split('.'))}
                                            </ListItemAvatar>
                                            <ListItemText primary={file.name} secondary={bytesConvert(file.size)} sx={{ color: "#212B36" }} />
                                            <IconButton edge="end" aria-label="comments" onClick={() => deleteNewFile(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </div>
                        }
                    </List>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                        <div style={{ marginBottom: 10 }}>檔案網址</div>
                        <Button variant="text" onClick={() => setUrlArray(prev => [...prev, ""])}>新增欄位</Button>

                    </div>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                        {
                            urlArray.map((url, index) =>
                                <ListItem key={index} sx={{ display: 'flex', alignItems: 'flex-start', "&.MuiListItem-root": { paddingLeft: 0 } }}>
                                    <TextField size="small" key={index} id="outlined-search" label="輸入您的檔案網址"
                                        value={url}
                                        sx={{ width: "100%", marginBottom: 2 }}
                                        onChange={(e) => {
                                            urlArray[index] = e.target.value.trim();
                                            setUrlArray([...urlArray])
                                        }}
                                    />
                                    <IconButton edge="end" onClick={() => {
                                        urlArray.splice(index, 1);
                                        setUrlArray([...urlArray]);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                            )}
                    </List>
                </CusBox>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadings}
                //   onClick={handleClose}
                >
                    <LoadingBar barInfo={barInfo} color="inherit" />

                </Backdrop>
            </Dialog>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ open: false, message: "" })} >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

        </div>
    );
}