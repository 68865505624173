import { getStorage, ref, getMetadata, getDownloadURL, listAll, deleteObject, uploadBytesResumable } from "firebase/storage";
import { db } from '../../../settings/firebaseConfig'
import { updateDoc, doc } from 'firebase/firestore';

const bytesConvert = (bytes) => {

    var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

}
const waitEach = async (array, callback) => {
    for (let i = 0; i < array.length; i++) {
        await callback(array[i], i);
    }
}

const getWebinarFiles = (webinarID, setMetaData) => {
    const storage = getStorage();
    const listRef = ref(storage, `webinar_doc/${webinarID}`);
    listAll(listRef)
        .then(async (res) => {
            let fileArray = [];
            console.log(res.items)
            await waitEach(res.items, async (itemRef) => {
                try {
                    const forestRef = ref(storage, itemRef);
                    const metaData = await getMetadata(forestRef);
                    const { name, size } = metaData;
                    fileArray.push({ name, size, itemRef });
                } catch (error) {
                    // error when get doc ref
                    console.log(error)
                }

            })
            setMetaData(fileArray);
        }).catch((error) => {
            // error when list all files
            console.log(error)
        });
}

const downloadFile = (itemRef) => {
    getDownloadURL(itemRef)
        .then((url) => { window.open(url, "_blank"); });
}

const updateWebinarFiles = async ({ webinarID, deleteRef, newFiles, urlArray, setBarInfo }) => {
    const storage = getStorage();
    const webinarRef = doc(db, "Webinar", webinarID);
    const filterURL = urlArray.filter(url => url !== "");
    console.log(deleteRef);
    try {

        await updateDoc(webinarRef, { resources: filterURL })
        let promiseArrayDelete = [];
        //delete file
        deleteRef.current.forEach(itemRef => promiseArrayDelete.push(deleteObject(itemRef)))
        await Promise.all(promiseArrayDelete);
        if (newFiles.length === 0) {
            setBarInfo({
                progress: 100,
                index: 1,
                total: 1
            });
            return
        }
        //upload file
        console.log(newFiles);
        await waitEach(newFiles, async (file, index) => {
            setBarInfo({
                progress: 0,
                index: index + 1,
                total: newFiles.length
            });
            const metadata = {
                contentType: file.type
            };
            const storageRef = ref(storage, `webinar_doc/${webinarID}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            await uploadTaskPromise(uploadTask, setBarInfo);
        });
        // newFiles.forEach(file => {
        //     const metadata = {
        //         contentType: file.type,
        //     };
        //     const storageRef = ref(storage, `webinar_doc/${webinarID}/${file.name}`);
        //     const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        //     await uploadTaskPromise(uploadTask, setBarInfo);
        // })

    } catch (e) {
        alert('Oops,something went wrong');
        console.log(e);
    }

}

const uploadTaskPromise = (uploadTask, setBarInfo) =>
    new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const percentage =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setBarInfo((prev) => ({
                    ...prev,
                    progress: percentage ? percentage : 10
                }));
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        console.log("running");
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                reject(error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                setTimeout(() => {
                    console.log("finished");
                    resolve();
                }, 500);
            }
        );
    });


export { bytesConvert, getWebinarFiles, downloadFile, updateWebinarFiles }